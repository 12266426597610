import { type Component, defineAsyncComponent } from 'vue'

import type { BlockType } from '@backmarket/http-api/src/api-specs-content/models/block'

export const COMPONENT_FROM_CONTENT_TYPE: Record<BlockType, Component> = {
  'Article-author': defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/Author.vue'),
  ),
  AnimatedProductCheckpoints: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/AnimatedProductCheckpoints.vue'),
  ),
  Button: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/Button.vue'),
  ),
  Buttons: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/Buttons.vue'),
  ),
  ArticleCardsList: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/ArticleCardsList.vue'),
  ),
  // TODO COM-1345 Remove this component
  BuyingGuides: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/ArticleCardsList.vue'),
  ),
  Carousel: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/Carousel.vue'),
  ),
  Categories: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/Categories.vue'),
  ),
  CategoryTree: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/CategoryTree.vue'),
  ),
  Content: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/Content.vue'),
  ),
  ContentMedia: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/ContentMedia.vue'),
  ),
  Countdown: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/Countdown.vue'),
  ),
  DiscountBanner: defineAsyncComponent(
    () => import('../../blocks/DiscountBanner/DiscountBanner.vue'),
  ),
  EmailCollector: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/EmailCollector.vue'),
  ),
  EmbeddedYoutubeVideo: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/EmbeddedYoutubeVideo.vue'),
  ),
  EnhancedCarousel: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/EnhancedCarousel.vue'),
  ),
  Faq: defineAsyncComponent(() => import('@backmarket/nuxt-layer-cms/Faq.vue')),

  ListItem: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/ListItem.vue'),
  ),
  Media: defineAsyncComponent(() => import('../../blocks/Media/Media.vue')),
  ModularCardsCarousel: defineAsyncComponent(
    () => import('../../blocks/ModularCard/ModularCardsCollection.vue'),
  ),
  ModularCardImage: defineAsyncComponent(
    () => import('../../blocks/ModularCard/ModularCardImage.vue'),
  ),
  PressCarousel: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/PressCarousel.vue'),
  ),
  Products: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/Products.vue'),
  ),
  Quiz: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/Quiz.vue'),
  ),
  Recommendations: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/Recommendations.vue'),
  ),
  RefinedProducts: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/RefinedProducts.vue'),
  ),
  Reinsurance: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/Reassurance.vue'),
  ),
  Reviews: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/Reviews.vue'),
  ),
  RelatedLinks: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/RelatedLinks.vue'),
  ),
  RichText: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/RichText.vue'),
  ),
  SectionBanner: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/SectionBanner.vue'),
  ),
  SocialMediaEmbed: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/SocialMediaEmbed.vue'),
  ),
  TrustpilotWidget: defineAsyncComponent(
    () => import('../../blocks/TrustpilotWidget/TrustpilotWidget.vue'),
  ),
  VideosBlock: defineAsyncComponent(
    () => import('@backmarket/nuxt-layer-cms/VideosBlock.vue'),
  ),
}
