/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

export const DEVICE = {
  Mobile: 'mobile',
  Desktop: 'desktop',
} as const

export const DEVICES: Device[] = [DEVICE.Mobile, DEVICE.Desktop]

/* TypeScript */
export type Device = (typeof DEVICE)[keyof typeof DEVICE]

/* Schemas */
export const DeviceSchema = z.enum([DEVICE.Mobile, DEVICE.Desktop])

/* Schemas validation */
type TestDevice = ExpectTrue<Equal<Device, z.infer<typeof DeviceSchema>>>
